const renderProgress = () => {
  const progressBar = document.getElementById('progress-bar');
  if(progressBar && progressBar.getAttribute('data-progress')){
    const progress = progressBar.getAttribute('data-progress') + '%';
    progressBar.style.width = progress;
    const amt = document.getElementById('progress-amt');
    amt.innerText = progress;
  }
}

document.addEventListener('turbolinks:load', renderProgress)
