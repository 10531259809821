import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";

const UserInviteSelectFields = ({defaultCourseId, coursesData, usersData}) => {

  const [courseId, setCourseId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [usersOptions, setUsersOptions] = useState([])


  useEffect(() => {
    setCourseId(defaultCourseId)
    setUsersOptions(getUsersOptionsForCourse(defaultCourseId))
  }, [defaultCourseId]); // set initial values from props

  useEffect(() => {
    // update users options every time when courseId is updated
    // set hidden course id value every time when courseId is updated
    courseId && setUsersOptions(getUsersOptionsForCourse(courseId))
    document.getElementById('invitation_course_id').value = courseId;
  }, [courseId])

  useEffect(() => {
    document.getElementById('invitation_user_id').value = userId;
  }, [userId]) // set hidden user id value every time when userId is updated

  const getUsersOptionsForCourse = courseId => {
    const userIdsToExclude = coursesData[courseId].users

    const usersToShow = Object.assign({}, usersData);

    userIdsToExclude.forEach(id => {
      delete usersToShow[id]
    });

    return Object.values(usersToShow);
  }

  const renderUsersOptions = () => {
    return usersOptions.map((option, index) =>
      <option key={index} value={option[0]}>{option[1]}</option>
    )
  }

  const getCoursesOptions = () => {
    const options = [];
    for (const course in coursesData) {
      options.push([coursesData[course].name, course])
    }

    return options;
  }

  const renderCoursesOptions = () => {
    const options = getCoursesOptions();

    return options.map((option, index) =>
      <option key={index} value={option[1]}>{option[0]}</option>
    )
  }

  return (
    <>
      <label htmlFor="user">User to invite:</label>
      {<select
        id="user"
        onChange={(e) => setUserId(e.target.value)}
        >
        <option value="">Choose user to invite</option>
        {renderUsersOptions()}
      </select>}

      <label htmlFor="course">Course:</label>
      {courseId && <select
        id="course"
        onChange={(e) => setCourseId(e.target.value)}
        defaultValue={courseId}>
        {renderCoursesOptions()}
      </select>}


  </>
  )
}

UserInviteSelectFields.propTypes = {
  defaultCourseId: PropTypes.string.isRequired,
  coursesData: PropTypes.object.isRequired,
  usersData: PropTypes.object.isRequired,
};

export default UserInviteSelectFields;
