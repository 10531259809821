import React, {useState, useEffect} from "react"

const UsernameField = ({user}) =>  {
    
    const initialFirstName = user.first_name ? user.first_name : '';
    const initialLastName = user.last_name ? user.last_name : '';
    // needed when the form rerenders with errors
    const initialUsername = user.username ? user.username : ''; 

    const [firstName, setFirstName] = useState(initialFirstName)
    const [lastName, setLastName] = useState(initialLastName)
    const [username, setUsername] = useState(initialUsername)

    useEffect(() => {
      updateUsername()
    }, [firstName, lastName]) //function inside useEffect is triggered every time firstName or lastName is updated
    
    const updateUsername = () => {
      setUsername(firstName + " " + lastName)
    }

    
    return (
      <>
        <label htmlFor="user_first_name" className="required" title="This field is required">First Name</label>
        <input 
          type="text" 
          name="user[first_name]" 
          id="user_first_name" 
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          required 
          maxLength={100}
          placeholder={"e.g. Betty"} />

        <label htmlFor="user_last_name" className="required" title="This field is required">Surname</label>
        <input 
          type="text"
          name="user[last_name]"
          id="user_last_name" 
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          required
          maxLength={100}
          placeholder={"e.g. Sunnocks"} />
        
        {username && <p>We have created an username for you, if you do not like it, feel free to change it </p>
        }
        <label 
          htmlFor="user_username" className="required"
          title="This field is required">Username</label>
        <input 
          type="text" 
          name="user[username]" 
          id="user_username" 
          value={username} 
          onChange={e => setUsername(e.target.value)} required
          maxLength={200}
          placeholder={"e.g Betty Sunnocks"}/>
      </>
    );
  
}

export default UsernameField
