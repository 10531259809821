
document.addEventListener('turbolinks:load', function(){

  const quizFormPage = document.getElementById('quiz-form');
  const arrangeSentenceFormPage = document.getElementById('arrange-a-sentence-form');
  const fillBlankFormPage = document.getElementById('fill-in-the-blank-form');
  const flashcardFormPage = document.getElementById('flashcard-form');
  const copycatFormPage = document.getElementById('copycat-quiz-form');
  const matchFormPage = document.getElementById('match-form');
  const findMistakeFormPage = document.getElementById('find-the-mistake-form');

  if(quizFormPage || arrangeSentenceFormPage || fillBlankFormPage || flashcardFormPage || copycatFormPage || matchFormPage || findMistakeFormPage){
    setItemNumber()

    $(document).on('cocoon:after-insert', function(e) {
      setItemNumber()
    })

    $(document).on('cocoon:after-remove', function(e) {
      setItemNumber()
    })

    $(document).on('ajax:success', function(e) {
      setItemNumber()
    })
  }

});


function setItemNumber(selector='h4 span.item-number'){
  const itemNumberElements = document.querySelectorAll(selector)

  const itemNumberElementsArray = Array.from(itemNumberElements)

  const visibleElements = itemNumberElementsArray.filter(el =>
    !!el.offsetParent)

  if (visibleElements.length > 0){
    visibleElements.forEach(function(element, index){
      element.innerText = index + 1;
    })
  }

}

