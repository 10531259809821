document.addEventListener('turbolinks:load', () => {
  const header = document.querySelector('header');
  if(header){
    window.addEventListener('scroll', () => {
      if(window.scrollY > header.offsetTop){
        if(!header.classList.contains('stuck')){
          header.style.position = 'fixed';
          header.style.width = '100%';
          document.querySelector('main').style.paddingTop = header.offsetHeight + 'px'
          header.classList.add('stuck');
        }
      } else {
        if(header.classList.contains('stuck')){
          header.style.position = 'inherit';
          header.style.width = 'inherit';
          header.style.marginBottom = 0;
          document.querySelector('main').style.paddingTop = '0px'
          header.classList.remove('stuck');
        }
      }
    })
  }
})
