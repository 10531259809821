export const red           =  '#FB3507'     ;
export const orange           =  '#FBBB2A'     ;
export const burntOrange           =  '#F0852E'     ;
export const pink           =  '#FDD7D5'     ;
export const green         =  '#35A45E'     ;
export const blue          =  '#319ADB'     ;
export const lightBlue     =  '#D0EDF3'     ;
export const darkBlue     =  '#1E243E'     ;
export const turquoise     =  '#B5E3D7'     ;
export const yellow        =  '#F8E31B'     ;
export const paleYellow        =  '#FAFBBB'     ;

export const black         =  '#000000'  ;
export const white         =  '#FFFFFF'  ;
export const lightGray    =  '#C4C4C4'     ;

export const primary       =  darkBlue     ;
export const secondary     =  turquoise     ;
export const success       =  green     ;
export const alert         =  yellow     ;
export const warning       =  red     ;

export const colorRef = {
  yellow: yellow,
  red: red,
  blue: blue,
  green: green,
  blue: blue,
  turquoise: turquoise,
  red: red
};
