import * as vis from './visibility'

export const renderSearchResults = () => {
  const searchListResults = document.getElementById('search-results')
  if(searchListResults){
    if (searchListResults.children.length == 0){
      searchListResults.innerText = '';
    }

    const assoc = searchListResults.getAttribute('data-assoc');
    const record = searchListResults.getAttribute('data-record');

    searchListResults.children.forEach(element => {

      element.addEventListener('click', e => {
        // after click get user id from clicked element
        // append that user to one_on_one form
        const id = element.getAttribute('data-id');
        const name = element.getAttribute('data-name');


        // check if checkbox for that user already exists
        // if no, create it
        const checkboxExists = checkIfCheckboxForUserExists(id, assoc)

        if (!checkboxExists && id && name){
          addRecordToForm(id, name, assoc, record);
        }

      })
    });
  }
}


function checkIfCheckboxForUserExists(id, assoc){
  let checkboxExists = false;
  const listContainer = document.getElementById('list-container');

  listContainer.children.forEach(element => {
    if(element.firstChild.id == `${assoc}_ids_${id}`){
      checkboxExists = true;
    }
  });

  return checkboxExists;
}


export const addRecordToForm = (id, name, assoc, record) => {
  // remove 'no added students' text if present
  // get checkboxes container
  // create new checkbox element for that user
  // append that element to the container

  vis.removeById('no-results-text');
  const listContainer = document.getElementById('list-container');

  const checkboxInput = document.createElement('input');
  checkboxInput.setAttribute('type', 'checkbox');
  checkboxInput.setAttribute('checked', 'true');
  checkboxInput.setAttribute('id', `${assoc}_ids_${id}`);
  checkboxInput.setAttribute('name', `${record}[${assoc}_ids][]`);
  checkboxInput.setAttribute('value', id);
  checkboxInput.classList = 'visually-hidden absolute';

  const label = document.createElement('label');
  label.htmlFor =  `${assoc}_ids_${id}`;
  label.innerText = name;
  label.classList = 'absolute top left visually-hidden';

  const visibleLabel = document.createElement('div');
  visibleLabel.innerText = name;
  visibleLabel.classList = 'text-checkbox-label row align-center-middle text-center';

  // add styling for checkboxes
  const inputDiv = document.createElement('div');
  inputDiv.classList = 'cell medium-6 text-checkbox-container big relative mmt-small-only';

  inputDiv.appendChild(checkboxInput);
  inputDiv.appendChild(label);
  inputDiv.appendChild(visibleLabel);

  listContainer.appendChild(inputDiv);
}

document.addEventListener("turbolinks:load", renderSearchResults);
document.addEventListener('ajax:success', renderSearchResults);
