document.addEventListener("turbolinks:load", function () {
  let videos = document.querySelectorAll("video");
  videos.forEach((video) => {
    video.addEventListener("contextmenu", (e) => e.preventDefault());
  });
  const playButtons = document.getElementsByClassName("lesson-play-button");
  if (playButtons.length > 0) {
    for (let i = 0; i < playButtons.length; i++) {
      let playButton = playButtons[i];
      let parentElement = playButton.parentElement;
      let image = parentElement.getElementsByClassName("lesson-image")[0];

      playButton.addEventListener("click", function () {
        const video = parentElement.getElementsByTagName("video")[0];
        video.classList.remove("hidden");
        openFullscreen(video);
        video.play();
        if (image) image.classList.add("hidden");
        playButton.classList.add("hidden");
      });
    }
  }
});

function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
}
