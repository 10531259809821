function setDataIndex() {
  // get lists of  file inputs & .valid-message & .invalid-message elements
  // all those lists have the same length, so we can use same index
  // to update data-index attribute
  const fileInputs = document.querySelectorAll('.set-data-index');
  const validMessages = document.querySelectorAll('.valid-message');
  const invalidMessages = document.querySelectorAll('.invalid-message');

  for (let index = 0; index < fileInputs.length; index++) {
    fileInputs[index].setAttribute('data-index', index)
    validMessages[index].setAttribute('data-index', index)
    invalidMessages[index].setAttribute('data-index', index)
  }
}

document.addEventListener('turbolinks:load', setDataIndex);
document.addEventListener('ajax:success', setDataIndex)

// Note - can i refactor this, will Jquery allow?
$(document).on('cocoon:after-insert', function(e) {
  setDataIndex()
})
