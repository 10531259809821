/**
 * Code based on those articles
 * https://tinloof.com/blog/how-to-create-an-accessible-react-modal/
 * https://dev.to/colettewilson/how-i-approach-keyboard-accessibility-for-modals-in-react-152p
 */

import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types";


const Modal = ({children, onModalClose, outerContainerClasses, innerContainerClasses}) => {

  let focusableElements = [];
  const activeElement = document.activeElement
  let activeIndex = -1;
  const modalRef = React.createRef();

  useEffect(() => {
    if (modalRef.current) {
      // Select all focusable elements within ref
      focusableElements = modalRef.current.querySelectorAll('a, button, textarea, input, select')
    }
  }, [modalRef])

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }

    document.addEventListener("keydown", keyListener);

    return () => {
      document.removeEventListener("keydown", keyListener);
      // Bring back focus to the previously focused element
      activeElement.focus();
    }
  });


  const handleTabKey = e => {
    const total = focusableElements.length;

    // If tab was pressed without shift
    if (!e.shiftKey) {
      // If activeIndex + 1 larger than array length focus first element otherwise focus next element
      activeIndex + 1 === total ? activeIndex = 0 : activeIndex += 1;
      focusableElements[activeIndex].focus();

      return e.preventDefault();
    }

    // If tab was pressed with shift
    if (e.shiftKey) {
      // if activeIndex - 1 less than 0 focus last element otherwise focus previous element
      activeIndex - 1 < 0 ? activeIndex = total - 1 : activeIndex -= 1;
      focusableElements[activeIndex].focus();

      return e.preventDefault();
    }
  };

   // map of keyboard listeners
   const keyListenersMap = new Map([
    [27, onModalClose],
    [9, handleTabKey],
  ]);

  return ReactDOM.createPortal (
    <div className={outerContainerClasses} role="dialog" aria-modal="true">
      <div className={innerContainerClasses} ref={modalRef}>
        <button className="close-button" title="close modal" onClick={onModalClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        {children}
      </div>
    </div>,
    document.body
  )
}



Modal.propTypes = {
  outerContainerClasses: PropTypes.string,
  innerContainerClasses: PropTypes.string,
};

Modal.defaultProps = {
  outerContainerClasses: 'modal-container grid-x align-center-middle',
  innerContainerClasses: 'modal-inner-container cell small-10 medium-6 large-5'
}

export default Modal
