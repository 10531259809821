import * as vis from './visibility';

const scrollUpDown = () => {
  const scrollContainers = document.querySelectorAll('.scroll-up-down-container');

  for(let i = 0; i < scrollContainers.length; i++){
    const scrollContainer = scrollContainers[i];

    // Find Up and Down scroll indicators
    let scroller;
    let up;
    let down;
    for(let i = 0; i < scrollContainer.children.length; i++){
      if(scrollContainer.children[i].classList.contains('scroller')){
        scroller = scrollContainer.children[i];
      } else if(scrollContainer.children[i].classList.contains('scroll-up')){
        up = scrollContainer.children[i];
      } else if(scrollContainer.children[i].classList.contains('scroll-down')){
        down = scrollContainer.children[i];
      }
    }

    if(scroller && up && down){

      // VISIBILITY

      toggleScrollArrows(scroller, up, down)

      // reset visibility when scrolling...
      scroller.addEventListener(
        'scroll',
        () => toggleScrollArrows(scroller, up, down)
      )

      // ... and when resizing the window
      window.addEventListener(
        'resize',
        () => toggleScrollArrows(scroller, up, down)
      )

      // HOVER LISTENERS

      let timer;

      const animateScrollUp = () => {
        timer = setInterval(
          () => scroller.scrollTop -= 5,
          20
        )
      };

      const animateScrollDown = () => {
        timer = setInterval(
          () => scroller.scrollTop += 5,
          20
        )
      };

      const clearTimer = () => {
        clearInterval(timer);
      }

      const enableHoverListeners = () => {
        up.firstElementChild.addEventListener('mouseenter', animateScrollUp)
        up.firstElementChild.addEventListener('mouseout', clearTimer)

        down.firstElementChild.addEventListener('mouseover', animateScrollDown)
        down.firstElementChild.addEventListener('mouseout', clearTimer)
      }

      const disableHoverListeners = () => {
        up.firstElementChild.removeEventListener('mouseenter', animateScrollUp)
        up.firstElementChild.removeEventListener('mouseout', clearTimer)

        down.firstElementChild.removeEventListener('mouseover', animateScrollDown)
        down.firstElementChild.removeEventListener('mouseout', clearTimer)
      }

      // initialize with hover listeners
      enableHoverListeners();

      // CLICK LISTENERS
      // When clicked, animate to other end,
      // disabling hover listeners for the duration

      up.firstElementChild.addEventListener('click', () => {
        disableHoverListeners();
        clearInterval(timer);
        scroller.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setTimeout(
          enableHoverListeners(),
          500
        )
      })

      down.firstElementChild.addEventListener('click', () => {
        disableHoverListeners();
        clearInterval(timer);
        scroller.scrollTo({
          top: scroller.scrollHeight - scroller.clientHeight,
          left: 0,
          behavior: 'smooth'
        });
        setTimeout(
          enableHoverListeners(),
          500
        )
      })
    }
  }
}


const toggleScrollArrows = (element, up, down) => {
  if(element.scrollHeight > element.clientHeight){
    if(element.scrollTop === 0) vis.fadeOut(up);
    else vis.fadeIn(up);
    if(element.scrollTop === (element.scrollHeight - element.clientHeight)) vis.fadeOut(down);
    else vis.fadeIn(down);
  } else {
    vis.fadeOut(up);
    vis.fadeOut(down);
  }
}




document.addEventListener('turbolinks:load', scrollUpDown);
