import anchorme from "anchorme"

const linkifyComments = () => {

  const comments = document.querySelectorAll('.linkify-comment')

  if (comments){
    comments.forEach(comment => {
      comment.innerHTML = anchorme(
        {
          input: comment.innerText,
          options: {
            attributes: {
              target: '_blank',
              rel: 'nofollow'
            }
          }
        }
      )
    })
  }
}

document.addEventListener('turbolinks:load', linkifyComments);
document.addEventListener('ajax:success', linkifyComments);

export default linkifyComments;
