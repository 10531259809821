  import React from "react";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from 'video-react';
import "video-react/styles/scss/video-react";

class Video extends React.Component {
  render () {
    
    let { src, poster, klass, id } = this.props;

    return (
      <div 
        className={`lmb ${klass ? klass : ''}`}
        id={id ? id : ''}
      >
        <Player
          src={src}
          poster={poster ? poster : null}
          preload={'auto'}
          playsInline
        >
          <BigPlayButton position="center" />
        </Player>
      </div>
    );
  }
}

Video.propTypes = {
  item: PropTypes.object
};

export default Video
