import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";

const DuplicateLessonSelectFields = ({defaultLesson, coursesData}) => {
  const [lessonId, setLessonId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [lessonsOptions, setLessonsOptions] = useState([]);

  useEffect(() => {
    setLessonId(defaultLesson.id);
    setCourseId(defaultLesson.course_id);
    setLessonsOptions(getLessonsOptionsForCourse(defaultLesson.course_id))
  }, [defaultLesson]) // set initial values from props

  useEffect(() => {
    document.getElementById('lesson_id').value = lessonId;
  }, [lessonId]) // update lesson id value every time when lessonId is updated

  useEffect(() => {
    courseId && setLessonsOptions(getLessonsOptionsForCourse(courseId));
  }, [courseId]) // update lessons options every time when courseId is updated


  const getLessonsOptionsForCourse = courseId => {
    return coursesData[courseId].lessons;
  };

  const getCoursesOptions = () => {
    const options = [];
    for (const course in coursesData) {
      options.push([coursesData[course].name, course])
    }

    return options;
  }

  const renderCoursesOptions = () => {
    const options = getCoursesOptions();

    return options.map((option, index) =>
      <option key={index} value={option[1]}>{option[0]}</option>
    )
  }

  const renderLessonsOptions = () => {
    return lessonsOptions.map((option, index) =>
    <option key={index} value={option[1]}>{option[0]}</option>
    )
  }


  return (
    <>
      <label htmlFor="course">Choose the Course to search for Lessons in</label>
      {courseId && <select
        id="course"
        onChange={(e) => setCourseId(e.target.value)}
        defaultValue={courseId}>
        {renderCoursesOptions()}
      </select>}

      <label htmlFor="lesson">Choose the Lesson to duplicate</label>
      {lessonId && <select
        id="lesson"
        onChange={(e) => setLessonId(e.target.value)}
        defaultValue={lessonId}>
        {renderLessonsOptions()}
      </select>}


    </>
  )
}

DuplicateLessonSelectFields.propTypes = {
  defaultLesson: PropTypes.object.isRequired,
  coursesData: PropTypes.object.isRequired,
};

export default DuplicateLessonSelectFields;
