// writes some code

document.addEventListener('turbolinks:load', () => {
  // get all the .collapsible-triggers
  const triggers = document.querySelectorAll('.collapsible-trigger');
  // for loop through them
  for(let i = 0; i < triggers.length; i++) {
    // add an event listener
    triggers[i].addEventListener('click', (event) => {
      // get the collapsible element
      const id = event.currentTarget.getAttribute('data-collapsible');
      if(!id) return;

      const classToggleableId = event.currentTarget.getAttribute(`data-class-toggleable`);
      let classToggleable;
      if(classToggleableId){
        classToggleable = document.querySelector(`#${classToggleableId}`);
        if(classToggleable) classToggleable.classList.toggle('collapsed');
      }
      
      const collapsible = document.querySelector(`#${id}.collapsible-content`);
      if(!collapsible) return;

      // toggle the collapsed state
      if(collapsible.style.maxHeight){
        collapsible.style.maxHeight = null;
        if(classToggleable) classToggleable.style.paddingBottom = null; 
      } else {
        // effectively unlimited maxheight # BIT OF HACK
        collapsible.style.maxHeight = 1000000000 + "px";
        if(classToggleable) classToggleable.style.paddingBottom = '32px' 
      }

      event.target.classList.toggle('is-open');
    });
  }
})