import React from "react"
import PropTypes from "prop-types"
import MyContext from './MyContext';

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      playing: false,
      loop: false,
      rate: 1,
    };
    this.audioContext = new AudioContext();
    this.audio = React.createRef();
    this.track = null;
    this.onLoad = this.onLoad.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handleEnded = this.handleEnded.bind(this);
    this.toggleRate = this.toggleRate.bind(this);
  }


  onLoad(){
    if(!this.track){
      this.track = this.audioContext.createMediaElementSource(this.audio.current);
      this.track.connect(this.audioContext.destination);
    }
    this.setState({loading: false});
  }


  handlePlay(){
    if(!this.state.loading){
      if(this.audioContext.state === 'suspended') this.audioContext.resume();
      if(this.state.playing){
        this.audio.current.pause();
        this.setState({ playing: false});
      } else {
        this.audio.current.play();
        this.setState({ playing: true});
      }
    }
  }


  handleEnded(){
    this.setState({playing: false});
  }


  toggleRate(){
    const rate = this.state.rate === 1 ? 0.5 : 1;
    this.setState(
      { rate: rate },
      () => this.audio.current.playbackRate = rate
    );
  }


  render () {
    const {
      rate,
      loading,
      playing,
      loop
    } = this.state;

    if(!this.props.audio) return null;

    // Images can be provided via props or Context
    let { images } = this.props;
    if(!images && this.context && this.context.images){ 
      images = this.context.images;
    }

    return (
      <div className='row align-center fade-in'>
        <audio
          ref={this.audio}
          src={this.props.audio.src}
          alt={this.props.audio.name}
          loop={loop}
          onLoadedData={this.onLoad}
          onEnded={this.handleEnded}
          crossOrigin="anonymous" // required for AWS CORS
        />

        <div className='audio-player-container row-or-column'>
          <button
            className={`button audio-player ${loading ? '' : 'loaded'} ${playing ? 'playing' : ''}`} onClick={this.handlePlay}>
            {loading && <h1>Loading</h1>}

            {!loading &&
              <React.Fragment>
                <label>{playing ? 'Playing...' : this.props.text}</label>
                <img src={images.listening} alt="headphones" className='audio-player-icon'/>
              </React.Fragment>
            }

          </button>
          <div className='column-or-row fn-button-container align-justify'>
            <button
              className={`lf-audio-fn-button button slow ${rate === 0.5 ? 'selected' : ''}`}
              onClick={this.toggleRate}
            >
              slow
              {/* <img
                className="turtle-icon"
                src={images.turtle_black}
                alt='turtle'
              />
              <img
                className="replay-icon"
                src={images.replay_black}
                alt='replay'
              /> */}
            </button>
            <button
              onClick={() => this.setState({ loop: !loop })}
              className={`lf-audio-fn-button button ${loop ? 'loop' : ''}`}
            >
              replay
              {/* <img
                className={`replay-icon ${loop ? 'fa-spin' : ''}`}
                src={images.replay_black}
                alt='replay'
              /> */}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  audio: PropTypes.object,
  text: PropTypes.string,
  audibleId: PropTypes.number,
};

AudioPlayer.defaultProps = {
  text: 'Click to listen',
};

AudioPlayer.contextType = MyContext;

export default AudioPlayer;
