import * as colors from './colors'

document.addEventListener("turbolinks:load", () => {
  const selects = document.querySelectorAll('.highlight-selected');

  if(selects && selects.length > 0){
    for(let i = 0; i < selects.length; i++){
      const sel = selects[i];

      // highlight if already selected
      for(let j = 0; j < sel.children.length; j++){
        // loop through options
        const option = sel.children[j];
        // If it has a value - i.e is not a placeholder - and is not selected
        if(option.value && option.selected){
          // highlight
          sel.style.backgroundColor = colors.alert;
          break;
        }
      }

      // add select listener
      sel.addEventListener('change', () => {
        if(sel.value){
          sel.style.backgroundColor = colors.alert;
          sel.style.fontWeight = 700;
        } else {
          sel.style.backgroundColor = 'white';
          sel.style.fontWeight = 300;
        }
      })
    }
  }
})
